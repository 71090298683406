@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Red Hat Display', sans-serif;
  margin: 0;
}

body {
  overflow-y: hidden;
  height: 100%;
}

/* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #fcd535;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px darkgrey;
  border-radius: 5px;
} */

.chart {
  /* background: rgba(33, 33, 33, 0.5); */
  background-color: transparent;
  background-image: url('./assets/Exchange/Home.png');
  color: white;
  fill: white;
  background-size: cover;
  /* height: 100%; */
}

.apexcharts-text {
  color: white;
  fill: white;
}

.apexcharts-tooltip {
  fill: black !important;
  color: black !important;
}

.apexcharts-menu-item {
  background: black;
  border-radius: 4px;
  border-bottom: 1px solid white;
}
.apexcharts-menu-item:hover {
  background: green;
  color: white;
}

.apexcharts-menu {
  border: none !important;
  background: none !important;
}

.ReactTradingViewChart {
  height: 1000vh;
}
